// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { dealModel } from '../model/deals-pipeline-setting.model'

@Injectable()
export class DealsPipelineService {
	constructor(
		private http: HttpClient
	) { }
	httpOptions = {
		headers: new HttpHeaders({
		  'Content-Type': 'application/json'
		})
	  };
	  getMyData(): Observable<any> {
		return this.http.get<any>('api/CRM/OpportunitySetting');
	}
  	addDealsetting(Dealset): Observable<dealModel> {
		console.log(Dealset)
		return this.http.post<dealModel>('api/CRM/OpportunitySetting?isReasonRequired='+Dealset.isReasonRequired+ '&isProposalAttachmentRequired='+Dealset.proposalAttachment +'&isWonAttachmentRequired='+ Dealset.wonAttachment,'');
	  }
 	getReason(): Observable<any> {
		return this.http.get<any>('api/CRM/OpportunityReasons')
	}

	createReason(tag: any): Observable<any> {
		 
        return this.http.post<dealModel>('api/CRM/OpportunityReason?reason='+tag, this.httpOptions);
	}
	deleteReason(Id: number): Observable<any> {
		return this.http.delete<dealModel>(`api/CRM/OpportunityReason/${Id}`);
	}
}
