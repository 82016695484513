// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { map} from 'rxjs/operators';

@Injectable()
export class TeamService {
	constructor(
		private http: HttpClient,
	) { }

	// My Team With GC-Detail
	myTeamWithGCDetail(from, to, text): Observable<any> {
		const params =
		{
			'from': from,
			'to': to,
			'text': text,
			'Status': 'all'
		};
		return this.http.post('api/CRM/MyTeamWithGCDetail', params)
			.pipe(map(res => res));
	}

	// Call Logs
	getCallLogs(userId,fromDate,toDate): Observable<any> {
		return this.http.get('api/CRM/CallLogsByUserId?userId='+ userId +'&fromDate=' +fromDate + '&toDate='+toDate)
			.pipe(map(res => res));
	}

	// Timeline
	getTimeline(timelineData): Observable<any> {
		return this.http.post('api/CRM/TimeLine', timelineData)
			.pipe(map(res => res));
	}

	// User Activity Dashboard
	getUserActivityDashboard(UserId): Observable<any> {
		return this.http.get('api/CRM/UserActivityDashboard?UserId=' + UserId)
			.pipe(map(res => res));
	}

	// User Report
	getUserReport(meetingData): Observable<any> {
		return this.http.post('api/UserReport', meetingData)
			.pipe(map(res => res));
	}

	// Meetings
	getMeetings(meetingData): Observable<any> {
		return this.http.post('api/CRM/LeadRoster', meetingData)
			.pipe(map(res => res));
	}

	// Upcoming Meetings
	getUpcomingMeetings(upcomingMeetingData): Observable<any> {
		return this.http.post('api/CRM/LeadRoster', upcomingMeetingData)
			.pipe(map(res => res));
	}

	// Attendances
	getAttendances(attendancedata): Observable<any> {
		return this.http.post('api/GC/DailyAttendanceReport', attendancedata)
			.pipe(map(res => res));
	}

	// Logs
	getLogs(logdata): Observable<any> {
		return this.http.get('api/AppLogs?ForUserId=' + logdata.ForUserId + '&From=' + logdata.From + '&FromDate=' + logdata.FromDate + '&To=' + logdata.To + '&ToDate=' + logdata.ToDate + '')
			.pipe(map(res => res));
	}

	//Team Wise Global Map Data
	getTeamWiseGlobalMapData(date): Observable<any>{
		return this.http.get('api/CRM/MyTeamDateWiseGlobalMapDataForWeb?date='+date)
			.pipe(
				map(res => res)
			);
	}

	//User Wise Global Map Data
	getUserWiseGlobalMapData(userId, date): Observable<any>{
		return this.http.get('api/UserDateWiseGlobalMapData?userId='+ userId +'&date=' +date)
			.pipe(
				map(res => res)
			);
	}

	//User Wise Global Map Data v1 furkan
	userLocations(userId, fromDate,toDate): Observable<any>{
		return this.http.get('api/User/Location?userId='+ userId +'&fromDate=' +fromDate + '&toDate='+toDate)
			.pipe(
				map(res => res)
			);
	}

	 //Get Regularization Data
	 getRegularizationDetails(params): Observable<any>{
		return this.http.post('api/AttendanceRegularization', params)
		  .pipe(map(res => res));
	}
	punchFilterData(data): Observable<any>{
		return this.http.post('api/CRM/MyTeamWithGCDetail', data)
			.pipe(map(res => res));
	}
}
