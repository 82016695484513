import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kt-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  constructor() { }
  @Input() title;
  @Input() Opportunity;
  @Input() isCloseButton;
  @Input() opportunityView;
  @Output() performAction: EventEmitter<any> = new EventEmitter()

  ngOnInit() {
  }
  emitEvent() {
    this.performAction.emit(true)
  }
}
