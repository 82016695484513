import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent, MatSelect } from "@angular/material";
import { Router } from "@angular/router";
import { NgbDropdown, NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from "rxjs/operators";
import { AddressService } from "../../../../../core/_base/crud/services/address.service";
import { AuthService } from "../../../../../core/auth/_services";
import { LeavesService } from "../../../../../core/leaves/leaves.service";
import { MyreferralsService } from "../../../../../core/myreferrals/myreferrals.service";
import { OrganizationalUnitService } from "../../../../../core/orgnizational-units/organizational-unit.service";
import { OpportunitiesService } from "../../../../../core/crm/opportunities/_services/opportunities.service";
import { TeamService } from '../../../../../core/crm/team/_services/team.service';

interface PunchStatus {
	value: string;
	viewValue: string;
}

@Component({
	selector: "kt-filter",
	templateUrl: "./filter.component.html",
	styleUrls: ["./filter.component.scss"],
	providers: [NgbDropdownConfig],
})
export class FilterComponent implements OnInit, OnChanges {
	@ViewChild("filterDropdown", { static: true }) dropdown: NgbDropdown;
	@ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
	@ViewChild("customerMulti", { static: true }) customerMulti: MatSelect;
	@Output() filterApplyEvent = new EventEmitter();
	@Input() filterFormat: string;
	@Input() customerStatusList = [];
	@Input() customerStageList = [];
	@Input() customerTypeList = [];
	@Input() customerSourceList = [];
	@Input() customerTerritoryList = [];
	@Input() customerTagList = [];
	@Input() managerList = [];
	@Input() referralList: any;
	@Input() referralListArray: any;
	@Input() referralCustomerList: any;
	@Input() referralCustomerListArray: any;
	@Input() DepartmentList = [];
	@Input() BranchList = [];
	@Input() DesignationList = [];
	@Input() CategoryList = [];
	@Input() teamList = [];
	@Input() filterName: string;
	@Input() tabIndex: number;
	@Input() SortList = [];
	@Input() clear: boolean;
	dummyCustomerArray: any = [];
	teamFilter: any = [];
	// teamList = [];
	current: Date = new Date();
	customerFilterForm: FormGroup;
	Fromdate: Date;
	toDate: any;
	subscriptions: Subscription[] = [];
	countryList = [];
	stateList = [];
	cityList = [];
	localityList = [];
	filterArray = [];
	filterArrayState = [];
	filterArrayCity = [];
	leaveTypes = [];
	filterLeave = [];
	opportunitiesDateType = [
		{ value: "CreateDate", viewValue: "Create Date" },
		{ value: "ModifiedDate", viewValue: "Modified Date" },
		{ value: "CloseDate", viewValue: "Close Date" },
	];
	customersDateType = [
		{ value: "CreatedDate", viewValue: "Create Date" },
		{ value: "ModifiedDate", viewValue: "Modified Date" },
	];

	clearData: boolean = false;
	viewLoading: boolean = false;
	showError: boolean = false;
	statusFilter: any[] = [
		{ name: "Pending", value: "1" },
		{ name: "Approved", value: "2" },
		{ name: "Rejected", value: "3" },
	];
	noRecords: boolean = false;

	filterData1 = {
		From: 1,
		To: 10,
		searchText: "",
	};

	filterDataParentId = {
		From: 1,
		Text: "",
		To: -1,
		IsActive: true,
	};

	totalRecords: number = 0;
	filteredDataList = {
		LocationFilter: [
			{
				CountryFilter: [],
				StateFilter: [],
				CityFilter: [],
				LocalityFilter: [],
			},
		],
		StatusFilter: [],
		leaveStatusFilter: [],
		StageFilter: [],
		SourceFilter: [],
		TypeFilter: [],
		TerritoryFilter: [],
		TagFilter: [],
		MemberFilter: [],
		CategoryFilter: [],
		FromDate: "",
		ToDate: "",
		MinValueFilter: "",
		MaxValueFilter: "",
		SortFilter: [],
		Departmentfilter: [],
		Designationfilter: [],
		Branchesfilter: [],
		LeaveFilter: [],
		ReferralCode: [],
		UserStatusIds: "",
		CompanyName: "",
	};
	filterData = {
		CountryId: "",
		StateId: "",
		CityId: "",
		LocalityId: "",
		StatusIds: "",
		leaveStatusIds: "",
		UserStatusIds: "",
		SourceIds: "",
		StageIds: "",
		TypeIds: "",
		TerritoryIds: "",
		TagIds: "",
		UserIds: "",
		Category: "",
		FromDate: "",
		ToDate: "",
		dateType: "",
		MinValue: "",
		MaxValue: "",
		Sort: "",
		LeaveId: "",
		PunchDetails: "",
		PunchDetailsCheck: "",
		ReferBy: "",
		ReferralCode: "",
		CompanyName: "",
		Department: "",
		Branches: "",
		Designation: "",
	};
	totlLeads: any = 0;
	@ViewChild("filter", { static: false }) filterLeaveValue: ElementRef;
	public memberMultiFilterCtrl: FormControl = new FormControl();
	public filteredMemberMulti: ReplaySubject<[]> = new ReplaySubject<[]>(1);
	protected _onDestroy = new Subject<void>();

	public customerMultiFilterCtrl: FormControl = new FormControl();
	public filteredCustomerMulti: ReplaySubject<[]> = new ReplaySubject<[]>(1);
	punchStatus: PunchStatus[] = [
		{ value: "PunchedIn", viewValue: "Punched In" },
		{ value: "PunchedOut", viewValue: "Punched Out" },
		{ value: "NotPunchedIn", viewValue: "Not Punched In" },
	];
	selected = -1;
	currentUrl: any;
	autoClose: boolean = false;

	public branchCtrl: FormControl = new FormControl();
	public filterBranchesArray: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	public departmentCtrl: FormControl = new FormControl();
	public filterDepartmentArray: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	public designationCtrl: FormControl = new FormControl();
	public filterDesignationArray: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	departmentIdfilter = [];

	designationIdfilter = [];

	branchIdfilter = [];

	currentUser: any;
	referralListOfBusiness: any[] = [];
	referralReferByListArray: any[] = [];
	temp: any[] = [];
	pageData = {
		From: 1,
		To: 10,
		Text: '',
	}
	customerList: any = []
	offset = 0;
	invalidUser: boolean = false;
	constructor(
		private service: OpportunitiesService, 
		private fb: FormBuilder, 
		private addressService: AddressService, 
		private authService: AuthService, 
		private route: Router, 
		private leaveService: LeavesService, 
		private ref: ChangeDetectorRef, 
		private organizationalunitservice: OrganizationalUnitService, 
		private myreferralService: MyreferralsService,
		private teamService: TeamService) {
		// customize default values of dropdowns used by this component tree
		// config.autoClose = false;
	}

	ngOnInit() {
		if (this.filterName == "attClaim" || this.filterName == "teamClaims") {
			this.statusFilter = [...this.statusFilter];
		} else if (this.filterName == "myreferrals") {
			this.statusFilter = [...this.statusFilter, { name: "Successful Signups", value: "4" }];
		} else {
			this.statusFilter = [...this.statusFilter, { name: "Expired", value: "4" }];
		}

		if (this.filterName == "leave") {
			this.getleaves();
		} else {
			this.getCountryList();
		}
		if (this.filterName == "opportunities") {
			
			this.getCustomerList();
		}

		this.authService.getPermissions().subscribe((x) => (this.currentUser = x.currentUser));

		this.initCustomerFilterForm();
		if (this.filterName == "customer") {
			this.customerFilterForm.controls["dateType"].patchValue(this.customersDateType[1].value);
		}


		if (this.filterName == "opportunities") {
			this.customerFilterForm.controls["dateType"].patchValue(this.opportunitiesDateType[0].value);
			this.Fromdate = new Date();
		}
		this.showError = false;
		if (this.clear == true) {
			this.closeDropdown();
		}
		let tempTeam = ["roster", "from", "leave", "teamClaims", "order", "expense", "mytask"];
		if (tempTeam.includes(this.filterName)) {
			this.myTeam();
		}
		let team = ["visitFrequency"];
		if (team.includes(this.filterName)) {
			this.getMyTeam();
		}

		this.memberMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.filterMemberMulti();
		});

		this.customerMultiFilterCtrl.valueChanges.pipe(debounceTime(2000),
			distinctUntilChanged(), takeUntil(this._onDestroy)).subscribe(() => {
				this.filterCustomerMulti();
			});
		this.currentUrl = this.route.url;

		// this.getReferalList(this.currentUser.CompanyDetail.ReferralCode);
		if (this.filterName == "myreferrals" || this.filterName == "mybusiness" || this.filterName == "customer") this.getReferralData();
		this.branchCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.filterBranch();
		});
		this.departmentCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.filterDepartment();
		});
		this.designationCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.filterDesignation();
		});
		this.customerFilterForm.controls["dateType"].valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((res) => {
			if (!res) return;
			this.customerFilterForm.controls["FromDate"].patchValue("");
			this.customerFilterForm.controls["ToDate"].patchValue("");
			if (res == "CreateDate" || res == "ModifiedDate") {
				this.Fromdate = new Date();
			} else {
				this.Fromdate = null;
			}
		});
	}


	filterBranch() {
		if (!this.branchIdfilter) {
			return;
		}
		// get the search keyword
		let search = this.branchCtrl.value;
		if (!search) {
			this.filterBranchesArray.next(this.branchIdfilter.slice());

			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the Country Code
		this.filterBranchesArray.next(this.branchIdfilter.filter((Parent) => Parent.Name.toLowerCase().indexOf(search) > -1));
	}

	filterDepartment() {
		if (!this.departmentIdfilter) {
			return;
		}
		// get the search keyword
		let search = this.departmentCtrl.value;
		if (!search) {
			this.filterDepartmentArray.next(this.departmentIdfilter.slice());

			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the Country Code
		this.filterDepartmentArray.next(this.departmentIdfilter.filter((Parent) => Parent.Name.toLowerCase().indexOf(search) > -1));
	}

	filterDesignation() {
		if (!this.designationIdfilter) {
			return;
		}
		// get the search keyword
		let search = this.designationCtrl.value;
		if (!search) {
			this.filterDesignationArray.next(this.designationIdfilter.slice());

			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the Country Code
		this.filterDesignationArray.next(this.designationIdfilter.filter((Parent) => Parent.Name.toLowerCase().indexOf(search) > -1));
	}

	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe());
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	ngOnChanges(change: SimpleChanges) {
		if (change.DesignationList) {
			this.designationIdfilter = this.DesignationList;
			if (this.designationIdfilter) {
				this.filterDesignationArray.next(this.designationIdfilter.slice());
			}
		}
		if (change.DepartmentList) {
			this.departmentIdfilter = this.DepartmentList;
			if (this.departmentIdfilter) {
				this.filterDepartmentArray.next(this.departmentIdfilter.slice());
			}
		}
		if (change.BranchList) {
			this.branchIdfilter = this.BranchList;
			if (this.branchIdfilter) {
				this.filterBranchesArray.next(this.branchIdfilter.slice());
			}
		}

		if (change.teamList) {
			this.teamFilter = this.teamList;
			if (this.teamFilter && this.teamFilter.length > 0) {
				this.filteredMemberMulti.next(this.teamFilter.slice());
				this.setInitialValue();
			}
		}

		if (change.managerList) {
			this.teamFilter = this.managerList;
			if (this.teamFilter.length > 0) {
				this.filteredMemberMulti.next(this.teamFilter.slice());
				this.setInitialValue();
			}
		}
		if (change.tabIndex) {
			this.clearData = true;
			if (this.customerFilterForm) {
				this.customerFilterForm.controls["ToDate"].clearValidators();
				this.customerFilterForm.controls["ToDate"].updateValueAndValidity();
				this.customerFilterForm.reset();
			}
			this.showError = false;
			this.viewLoading = false;
			this.dropdown.close();
			this.filterData = {
				CountryId: "",
				StateId: "",
				CityId: "",
				LocalityId: "",
				StatusIds: "",
				leaveStatusIds: "",
				UserStatusIds: "",
				SourceIds: "",
				StageIds: "",
				TypeIds: "",
				TerritoryIds: "",
				TagIds: "",
				UserIds: "",
				Category: "",
				FromDate: "",
				ToDate: "",
				dateType: "",
				MinValue: "",
				MaxValue: "",
				Sort: "",
				LeaveId: "",
				PunchDetails: "",
				PunchDetailsCheck: "",
				Department: "",
				Branches: "",
				Designation: "",
				ReferBy: "",
				ReferralCode: "",
				CompanyName: "",
			};
			this.stateList = [];
			this.cityList = [];
			this.localityList = [];
		}
		if (this.filterName == "punchin_punchout") this.autoClose = true;
		else this.autoClose = false;
	}



	setInitialValue() {
		this.filteredMemberMulti.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
			if (this.multiSelect) {
				this.multiSelect.compareWith = (a, b) => a && b && a.UserId === b.UserId;
			}
		});
	}

	setInitialCustValue() {
		this.filteredCustomerMulti.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
			if (this.customerMulti) {
				this.customerMulti.compareWith = (a, b) => a && b && a.Id === b.Id;
			}
		});
	}

	filterMemberMulti() {
		if (!this.teamFilter) {
			return;
		}
		// get the search keyword
		let search = this.memberMultiFilterCtrl.value;
		if (!search) {
			this.filteredMemberMulti.next(this.teamFilter.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		// 	 the banks
		var x = Object.keys(this.teamFilter[0]);
		if (x.includes("UserName")) {
			this.filteredMemberMulti.next(this.teamFilter.filter((bank) => bank.UserName.toLowerCase().indexOf(search) > -1));
		} else {
			this.filteredMemberMulti.next(this.teamFilter.filter((bank) => bank.Name.toLowerCase().indexOf(search) > -1));
		}
	}

	filterCustomerMulti() {
		if (!this.dummyCustomerArray) {
			return;
		}
		// get the search keyword
		let search = this.customerMultiFilterCtrl.value;
		if (!search) {
			this.pageData.Text = ''; // Clear search term
			this.pageData.To = 10;
			this.getCustomerList();
			return;
		} else {
			search = search.toLowerCase();
		}
		this.pageData.Text = search;
		this.pageData.To = 10
		this.getCustomerList();
	}

	initCustomerFilterForm() {
		this.customerFilterForm = this.fb.group({
			CountryId: [""],
			StateId: [""],
			CityId: [""],
			LocalityId: [""],
			UserIds: [""],
			StatusIds: [""],
			leaveStatusIds: [""],
			LeaveId: [""],
			UserStatusIds: [""],
			SourceIds: [""],
			StageIds: [""],
			TypeIds: [""],
			TerritoryIds: [""],
			TagIds: [""],
			Category: [""],
			MinValue: [""],
			MaxValue: [""],
			FromDate: [""],
			ToDate: [""],
			dateType: [""],
			Sort: [""],
			Branches: [""],
			Designation: [""],
			dept: [""],
			branch: [""],
			desig: [""],
			Department: [""],
			ReferBy: [""],
			CompanyName: [""],
		}, { validator: this.dateRangeValidator });

		if (this.filterName == "leave" || this.filterName == "teamClaims") {
			let statusIds = ["1"];
			this.customerFilterForm.controls.leaveStatusIds.setValue(statusIds);
			this.applyFilter();
		}
	}

	getleaves() {
		this.leaveService
			.getLeaveTypes()
			.pipe(
				map((res) => {
					if (res) {
						this.leaveTypes = res.data;
						this.filterLeave = res.data;
					}
				})
			)
			.subscribe();
	}

	get form() {
		return this.customerFilterForm.controls;
	}

	getCountryList() {
		this.authService
			.GetCountry()
			.pipe(
				map((res) => {
					if (res) {
						this.countryList = res;
						this.filterArray = res;
					}
				})
			)
			.subscribe();
	}

	getStateList(Country) {
		this.addressService
			.getState(Country.CountryId)
			.pipe(
				map((res) => {
					if (res.length > 0) {
						this.stateList = res;
						this.filterArrayState = res;
						this.customerFilterForm.controls["CityId"].patchValue("");
						this.customerFilterForm.controls["LocalityId"].patchValue("");
						this.cityList = [];
						this.localityList = [];
					}
				})
			)
			.subscribe();
	}

	getCityList(State) {
		this.addressService
			.getCity(State.StateId)
			.pipe(
				map((res) => {
					if (res.length > 0) {
						this.cityList = res;
						this.filterArrayCity = res;
						this.customerFilterForm.controls["LocalityId"].patchValue("");
						this.localityList = [];
					}
				})
			)
			.subscribe();
	}

	getLocalityList(City) {
		this.addressService
			.getLocality(City.CityId)
			.pipe(
				map((res) => {
					if (res) {
						this.localityList = res;
					}
				})
			)
			.subscribe();
	}

	// getReferalList(referralCode) {
	// 	this.myreferralsService.getreferralList(referralCode)
	// 	  .pipe(map(res => {
	// 		if (res['Status'] == 200)
	// 		  this.referralList = res['data'];
	// 		this.referralListArray = res['data'];
	// 	  }))
	// 	  .subscribe()
	//   }

	myTeam() {
		this.addressService
			.myTeam()
			.pipe(
				map((res) => {
					if (res.Status == 200) {
						this.teamList = res.data.teamMembers;
						this.teamFilter = this.teamList;
						if (this.teamFilter.length > 0) {
							this.filteredMemberMulti.next(this.teamFilter.slice());
							this.setInitialValue();
						}
					}
				})
			)
			.subscribe();
	}
	
	getCustomerList() {
		console.log(this.customerList.length);
		if (this.totlLeads >= this.customerList.length) {
			this.service.getCustomerList(this.pageData)
				.pipe(map(res => {
					const custLength = res.data.Leads.length
					this.totlLeads = res.data.TotalRecords
					// this.lastCustomer = res.data.Leads[custLength - 1].Id
					this.customerList = res.data.Leads;
					this.dummyCustomerArray = this.customerList;
					if (this.dummyCustomerArray && this.dummyCustomerArray.length > 0) {
						this.filteredCustomerMulti.next(this.dummyCustomerArray.slice());
						this.pageData.To += 10
						this.setInitialCustValue();
					}
				}
				))
				.subscribe()
		}
	}
	getnextBatch() {
		console.warn('scroll hitting');

	}

	prepareFilter() {
		this.filterData = {
			CountryId: "",
			StateId: "",
			CityId: "",
			LocalityId: "",
			StatusIds: "",
			leaveStatusIds: "",
			UserStatusIds: "",
			SourceIds: "",
			StageIds: "",
			TypeIds: "",
			TerritoryIds: "",
			TagIds: "",
			UserIds: "",
			Category: "",
			FromDate: "",
			ToDate: "",
			dateType: "",
			MinValue: "",
			MaxValue: "",
			Sort: "",
			LeaveId: "",
			PunchDetails: "",
			PunchDetailsCheck: "",
			ReferBy: "",
			ReferralCode: "",
			CompanyName: "",
			Department: "",
			Branches: "",
			Designation: "",
		};
		const controls = this.customerFilterForm.controls;

		if (controls["CountryId"].value) {
			this.filterData.CountryId = controls["CountryId"].value.CountryId;
		}
		if (controls["StateId"].value) {
			this.filterData.StateId = controls["StateId"].value.StateId;
		}
		if (controls["CityId"].value) {
			this.filterData.CityId = controls["CityId"].value.CityId;
		}
		if (controls["LocalityId"].value) {
			this.filterData.LocalityId = controls["LocalityId"].value.LocalityId;
		}
		if (controls["StatusIds"].value) {
			if (this.filterName == "order" || this.filterName == "expense" || this.filterName == "opportunities") {
				this.filterData.StatusIds = controls["StatusIds"].value.map((e) => e.Id).join(", ");
			} else if (this.filterName == "mytask" || this.filterName == "users") {
				this.filterData.StatusIds = controls["StatusIds"].value["Id"];
			} else {
				this.filterData.StatusIds = controls["StatusIds"].value.map((e) => e.StatusId).join(", ");
			}
		}
		if (controls["Category"].value) {
			if (this.filterName == "mytask") {
				this.filterData.Category = controls["Category"].value["Id"];
			} else {
				this.filterData.Category = controls["Category"].value.map((e) => e.Id).join(", ");
			}
		}
		
		if (controls["Department"].value) {
			if (this.filterName == "mydepartment") {
				this.filterData.Department = controls["Department"].value["Id"];
			} else {
				this.filterData.Department = controls["Department"].value.map((e) => e.Id).join(", ");
			}
		}

		if (controls["Branches"].value) {
			if (this.filterName == "mybranches") {
				this.filterData.Branches = controls["Branches"].value["Id"];
			} else {
				this.filterData.Branches = controls["Branches"].value.map((e) => e.Id).join(",");
			}
		}
		
		if (controls["Designation"].value) {
			if (this.filterName == "mydesignation") {
				this.filterData.Designation = controls["Designation"].value["Id"];
			} else {
				this.filterData.Designation = controls["Designation"].value.map((e) => e.Id).join(", ");
			}
		}

		if (controls["leaveStatusIds"].value) {
			this.filterData.leaveStatusIds = controls["leaveStatusIds"].value;
		}
		if (controls["UserStatusIds"].value) {
			this.filterData.UserStatusIds = controls["UserStatusIds"].value;
		}
		if (controls["StageIds"].value) {
			this.filterData.StageIds = controls["StageIds"].value.map((e) => e.Id).join(", ");
		}
		if (controls["SourceIds"].value) {
			this.filterData.SourceIds = controls["SourceIds"].value.map((e) => e.Id).join(", ");
		}
		if (controls["TypeIds"].value) {
			this.filterData.TypeIds = controls["TypeIds"].value.map((e) => e.Id).join(", ");
		}
		if (controls["TerritoryIds"].value) {
			this.filterData.TerritoryIds = controls["TerritoryIds"].value.map((e) => e.TerritoryId).join(", ");
		}
		
		if (controls["ReferBy"].value) {
			this.filterData.ReferralCode = controls["ReferBy"].value.map((e) => e.referralUserId).join(", ");
		}
		if (controls["TagIds"].value) {
			if (controls["TagIds"].value.Tags) {
				this.filterData.TagIds = controls["TagIds"].value.Tags.map((e) => e.Id).join(", ");
			}
			// else {
				// 	this.filterData.TagIds = controls['TagIds'].value.map(e => e.Id).join(', ');
			// }
		}
		if (controls["UserIds"].value) {
			if (controls["UserIds"].value.searchMultipleCheckValue) {
				this.filterData.UserIds = controls["UserIds"].value.searchMultipleCheckValue.map((e) => e.UserId).join(", ");
			} else if (this.filterName == "users") {
				// this.filterData.UserIds = controls["UserIds"].value.UserId; // this is for all users
				this.filterData.UserIds = controls["UserIds"].value.Id; //  this is for only for managers
			} else {
				this.filterData.UserIds = controls["UserIds"].value.map((e) => e.UserId).join(", ");
			}
		}
		if (controls["dateType"].value) {
			if (this.filterName == "opportunities" || this.filterName == "customer") {
				this.filterData.dateType = controls["dateType"].value;
			}
		} else {
			if (this.filterName == "opportunities") {
				this.customerFilterForm.controls["dateType"].patchValue(this.opportunitiesDateType[0].value);
			} else if (this.filterName == "customer") {
				this.customerFilterForm.controls["dateType"].patchValue(this.customersDateType[1].value);
			}
		}
		if (controls["FromDate"].value) {
			this.filterData.FromDate = controls["FromDate"].value;
		}
		if (controls["ToDate"].value) {
			this.filterData.ToDate = controls["ToDate"].value;
		}
		if (controls["MinValue"].value) {
			this.filterData.MinValue = controls["MinValue"].value;
		}
		if (controls["MaxValue"].value) {
			this.filterData.MaxValue = controls["MaxValue"].value;
		}
		if (controls["Sort"].value) {
			this.filterData.Sort = controls["Sort"].value["Id"];
		}
		if (controls["LeaveId"].value) {
			this.filterData.LeaveId = controls["LeaveId"].value.Id;
		}
		if (controls["CompanyName"].value) {
			this.filterData.CompanyName = controls["CompanyName"].value;
		}
		return this.filterData;
	}
	
	applyFilter() {
		this.clearData = false;
		if (this.customerFilterForm.invalid) {
			return;
		}
		const filterData = this.prepareFilter();
		const filterList = this.getFilteredList();
		if (filterData && filterList) {
			setTimeout(() => {
				this.filterApplyEvent.emit({ filterData, filterList });
				this.dropdown.close();
				this.viewLoading = false;
			});
		}
	}

	getFilteredList() {
		this.filteredDataList = {
			LocationFilter: [
				{
					CountryFilter: [],
					StateFilter: [],
					CityFilter: [],
					LocalityFilter: [],
				},
			],
			StatusFilter: [],
			leaveStatusFilter: [],
			StageFilter: [],
			SourceFilter: [],
			TypeFilter: [],
			TerritoryFilter: [],
			TagFilter: [],
			MemberFilter: [],
			CategoryFilter: [],
			FromDate: "",
			ToDate: "",
			MaxValueFilter: "",
			MinValueFilter: "",
			SortFilter: [],
			LeaveFilter: [],
			ReferralCode: [],
			UserStatusIds: "",
			CompanyName: "",
			Departmentfilter: [],
			Designationfilter: [],
			Branchesfilter: [],
		};
		if (this.customerFilterForm.controls) {
			const controls = this.customerFilterForm.controls;
			if (controls["CountryId"].value) {
				this.filteredDataList.LocationFilter[0].CountryFilter.push(controls["CountryId"].value);
			}
			if (controls["StateId"].value) {
				this.filteredDataList.LocationFilter[0].StateFilter.push(controls["StateId"].value);
			}
			if (controls["CityId"].value) {
				this.filteredDataList.LocationFilter[0].CityFilter.push(controls["CityId"].value);
			}
			if (controls["LocalityId"].value) {
				this.filteredDataList.LocationFilter[0].LocalityFilter.push(controls["LocalityId"].value);
			}
			if (controls["StatusIds"].value) {
				if (this.filterName == "mytask" || this.filterName == "users") {
					this.filteredDataList.StatusFilter = [];
					this.filteredDataList.StatusFilter.push(controls["StatusIds"].value);
				} else {
					this.filteredDataList.StatusFilter = controls["StatusIds"].value;
				}
			}
			if (controls["Category"].value) {
				if (this.filterName == "mytask") {
					this.filteredDataList.CategoryFilter = [];
					this.filteredDataList.CategoryFilter.push(controls["Category"].value);
				} else {
					this.filteredDataList.CategoryFilter = controls["Category"].value;
				}
			}
			if (controls["Department"].value) {
				if (this.filterName == "mydepartment") {
					this.filteredDataList.Departmentfilter = [];
					this.filteredDataList.Departmentfilter.push(controls["Department"].value);
				} else {
					this.filteredDataList.Departmentfilter = controls["Department"].value;
				}
			}

			if (controls["Branches"].value) {
				if (this.filterName == "mybranches") {
					this.filteredDataList.Branchesfilter = [];
					this.filteredDataList.Branchesfilter.push(controls["Branches"].value);
				} else {
					this.filteredDataList.Branchesfilter = controls["Branches"].value;
				}
			}

			if (controls["Designation"].value) {
				if (this.filterName == "mydesignation") {
					this.filteredDataList.Designationfilter = [];
					this.filteredDataList.Designationfilter.push(controls["Designation"].value);
				} else {
					this.filteredDataList.Designationfilter = controls["Designation"].value;
				}
			}

			if (controls["leaveStatusIds"].value) {
				this.filteredDataList.leaveStatusFilter = controls["leaveStatusIds"].value;
			}
			if (controls["UserStatusIds"].value) {
				this.filteredDataList.UserStatusIds = controls["UserStatusIds"].value;
			}
			if (controls["ReferBy"].value) {
				if (controls["ReferBy"].value.searchMultipleCheckValue) {
					this.filteredDataList.ReferralCode = controls["ReferBy"].value.searchMultipleCheckValue;
				} else {
					this.filteredDataList.ReferralCode = controls["ReferBy"].value;
				}
			}
			if (controls["StageIds"].value) {
				this.filteredDataList.StageFilter = controls["StageIds"].value;
			}
			if (controls["SourceIds"].value) {
				this.filteredDataList.SourceFilter = controls["SourceIds"].value;
			}
			if (controls["TypeIds"].value) {
				this.filteredDataList.TypeFilter = controls["TypeIds"].value;
			}
			if (controls["TerritoryIds"].value) {
				this.filteredDataList.TerritoryFilter = controls["TerritoryIds"].value;
			}
			if (controls["TagIds"].value) {
				if (controls["TagIds"].value.Tags) {
					this.filteredDataList.TagFilter = controls["TagIds"].value.Tags;
				} else {
					this.filteredDataList.TagFilter = controls["TagIds"].value;
				}
			}
			if (controls["UserIds"].value) {
				if (controls["UserIds"].value.searchMultipleCheckValue) {
					this.filteredDataList.MemberFilter = controls["UserIds"].value.searchMultipleCheckValue;
				} else if (this.filterName == "users") {
					this.filteredDataList.MemberFilter = [];
					this.filteredDataList.MemberFilter.push(controls["UserIds"].value);
				} else {
					this.filteredDataList.MemberFilter = controls["UserIds"].value;
				}
			}
			if (controls["dateType"].value) {
				if (this.filterName == "opportunities" || this.filterName=="customer") {
					this.filterData.dateType = controls["dateType"].value;
				}
			}
			if (controls["FromDate"].value) {
				this.filteredDataList.FromDate = controls["FromDate"].value;
			}
			if (controls["ToDate"].value) {
				this.filteredDataList.ToDate = controls["ToDate"].value;
			}
			if (controls["MinValue"].value) {
				this.filteredDataList.MinValueFilter = controls["MinValue"].value;
			}
			if (controls["MaxValue"].value) {
				this.filteredDataList.MaxValueFilter = controls["MaxValue"].value;
			}
			if (controls["Sort"].value) {
				this.filteredDataList.SortFilter.push(controls["Sort"].value);
			}
			if (controls["LeaveId"].value) {
				this.filteredDataList.LeaveFilter.push(controls["LeaveId"].value);
			}
			if (controls["CompanyName"].value) {
				this.filteredDataList.CompanyName = controls["CompanyName"].value;
			}
			return this.filteredDataList;
		}
	}

	clearFilter(Name, value, index) {
		switch (Name) {
			case "Location":
				if (value == "locality") {
					this.customerFilterForm.patchValue({ LocalityId: "" });
				} else if (value == "city") {
					this.customerFilterForm.patchValue({
						CityId: "",
						LocalityId: "",
					});
				} else if (value == "state") {
					this.customerFilterForm.patchValue({
						StateId: "",
						CityId: "",
						LocalityId: "",
					});
				} else {
					this.customerFilterForm.patchValue({
						CountryId: "",
						StateId: "",
						CityId: "",
						LocalityId: "",
					});
				}
				break;
			case "Status":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ StatusIds: "" });
				} else if (this.filterName == "mytask" || this.filterName == "users") {
					this.customerFilterForm.patchValue({ StatusIds: "" });
				} else {
					this.customerFilterForm.controls["StatusIds"].value.splice(index, 1);
				}
				break;
			case "Category":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ Category: "" });
				} else {
					this.customerFilterForm.controls["Category"].value.splice(index, 1);
				}
				break;
				case "Department":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ Department: "" });
				} else {
					this.customerFilterForm.controls["Department"].value.splice(index, 1);
				}
				break;
			case "Branches":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ Branches: "" });
				} else {
					this.customerFilterForm.controls["Branches"].value.splice(index, 1);
				}
				break;
			case "Designation":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ Designation: "" });
				} else {
					this.customerFilterForm.controls["Designation"].value.splice(index, 1);
				}
				break;
			case "leaveStatusIds":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ leaveStatusIds: "" });
				} else {
					this.customerFilterForm.controls["leaveStatusIds"].value.splice(index, 1);
				}
				break;
			case "Stage":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ StageIds: "" });
				} else {
					this.customerFilterForm.controls["StageIds"].value.splice(index, 1);
				}
				break;
				case "Source":
					if (value == "multiple") {
					this.customerFilterForm.patchValue({ SourceIds: "" });
				} else {
					this.customerFilterForm.controls["SourceIds"].value.splice(index, 1);
				}
				break;
				
			case "ReferBy":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ ReferBy: "" });
				} else {
					this.customerFilterForm.controls["ReferBy"].value.splice(index, 1);
				}
				break;
				
				case "Tag":
				if (value == "multiple") {
					if (this.customerFilterForm.controls["TagIds"].value.Tags) {
						this.customerFilterForm.controls["TagIds"].value.Tags.splice(0, this.filteredDataList.TagFilter.length);
					} else {
						this.customerFilterForm.controls["TagIds"].value.splice(0, this.filteredDataList.TagFilter.length);
					}
				} else {
					this.customerFilterForm.controls["TerritoryIds"].value.splice(index, 1);
				}
				break;
				case "Tag":
					if (value == "multiple") {
					if (this.customerFilterForm.controls["TagIds"].value.Tags) {
						this.customerFilterForm.controls["TagIds"].value.Tags.splice(0, this.filteredDataList.TagFilter.length);
					} else {

						this.customerFilterForm.controls["TagIds"].value.splice(0, this.filteredDataList.TagFilter.length);
					}
				} else {
					if (this.customerFilterForm.controls["TagIds"].value.Tags) {
						this.customerFilterForm.controls["TagIds"].value.Tags.splice(index, 1);
					} else {
						this.customerFilterForm.controls["TagIds"].value.splice(index, 1);
					}
				}
				break;
			case "Member":
				if (value == "multiple") {
					if (this.customerFilterForm.controls["UserIds"].value.searchMultipleCheckValue) {
						this.customerFilterForm.controls["UserIds"].value.searchMultipleCheckValue.splice(0, this.filteredDataList.MemberFilter.length);
					} else if (this.filterName == "users") {
						this.customerFilterForm.patchValue({ UserIds: "" });
					} else {
						this.customerFilterForm.controls["UserIds"].value.splice(0, this.filteredDataList.MemberFilter.length);
					}
				} else {
					if (this.customerFilterForm.controls["UserIds"].value.searchMultipleCheckValue) {
						this.customerFilterForm.controls["UserIds"].value.searchMultipleCheckValue.splice(index, 1);
					} else if (this.filterName == "users") {
						this.customerFilterForm.patchValue({ UserIds: "" });
					} else {
						this.customerFilterForm.controls["UserIds"].value.splice(index, 1);
					}
				}
				break;
			case "Date":
				this.customerFilterForm.controls["ToDate"].clearValidators();
				this.customerFilterForm.controls["ToDate"].updateValueAndValidity();
				this.customerFilterForm.patchValue({ FromDate: "" });
				this.customerFilterForm.patchValue({ ToDate: "" });
				if (this.filterName == "opportunities") {
					this.customerFilterForm.patchValue({ dateType: this.opportunitiesDateType[0].value });
				} else if (this.filterName == "customer") {
					this.customerFilterForm.patchValue({ dateType: this.customersDateType[1].value });
				}

				break;
			case "Amount":
				this.customerFilterForm.patchValue({ MaxValue: "" });
				this.customerFilterForm.patchValue({ MinValue: "" });
				break;
			case "UserStatusIds":
				this.customerFilterForm.patchValue({ UserStatusIds: "" });
				break;
			case "Sort":
				this.customerFilterForm.patchValue({ Sort: "" });
				break;
			case "LeaveId":
				this.customerFilterForm.patchValue({ LeaveId: "" });
				break;
			case "CompanyName":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ CompanyName: "" });
				} else {
					this.customerFilterForm.controls["CompanyName"].value.splice(index, 1);
				}
				break;
			case "Type":
				if (value == "multiple") {
					this.customerFilterForm.patchValue({ TypeIds: "" });
				} else {
					this.customerFilterForm.controls["TypeIds"].value.splice(index, 1);
				}
				break;
		}
		this.customerFilterForm.patchValue({
			StatusIds: this.customerFilterForm.controls["StatusIds"].value,
			leaveStatusIds: this.customerFilterForm.controls["leaveStatusIds"].value,
			StageIds: this.customerFilterForm.controls["StageIds"].value,
			SourceIds: this.customerFilterForm.controls["SourceIds"].value,
			TypeIds: this.customerFilterForm.controls["TypeIds"].value,
			TerritoryIds: this.customerFilterForm.controls["TerritoryIds"].value,
			Category: this.customerFilterForm.controls["Category"].value,
			FromDate: this.customerFilterForm.controls["FromDate"].value,
			ToDate: this.customerFilterForm.controls["ToDate"].value,
			dateType: this.customerFilterForm.controls["dateType"].value,
			MaxValue: this.customerFilterForm.controls["MaxValue"].value,
			MinValue: this.customerFilterForm.controls["MinValue"].value,
			Sort: this.customerFilterForm.controls["Sort"].value,
			ReferBy: this.customerFilterForm.controls["ReferBy"].value,
			CompanyName: this.customerFilterForm.controls["CompanyName"].value,
			LeaveId: this.customerFilterForm.controls["LeaveId"].value,
			Department: this.customerFilterForm.controls["Department"].value,
			Branches: this.customerFilterForm.controls["Branches"].value,
			Designation: this.customerFilterForm.controls["Designation"].value,
		});
		if (this.customerFilterForm.controls["TagIds"].value) {
			if (this.customerFilterForm.controls["TagIds"].value.Tags) {
				this.customerFilterForm.patchValue({
					TagIds: this.customerFilterForm.controls["TagIds"].value.Tags,
				});
			} else {
				this.customerFilterForm.patchValue({
					TagIds: this.customerFilterForm.controls["TagIds"].value,
				});
			}
		}
		if (this.customerFilterForm.controls["UserIds"].value) {
			if (this.customerFilterForm.controls["UserIds"].value.searchMultipleCheckValue) {
				this.customerFilterForm.patchValue({
					UserIds: this.customerFilterForm.controls["UserIds"].value.searchMultipleCheckValue,
				});
			} else {
				this.customerFilterForm.patchValue({
					UserIds: this.customerFilterForm.controls["UserIds"].value,
				});
			}
		}

		setTimeout(() => {
			this.applyFilter();
		});
	}

	restrict(event: MatDatepickerInputEvent<Date>) {
		this.Fromdate = event.value;
		if (this.filterName == "opportunities") {
			let type = this.customerFilterForm.controls["dateType"].value;
			if (type == "CreateDate" || type == "ModifiedDate") {
				this.toDate = new Date();
			} else if (type == "CloseDate") {
				this.toDate = null;
			}
		}
		if (this.customerFilterForm.controls["FromDate"].touched) {
			this.customerFilterForm.controls["ToDate"].setValidators(Validators.required);
			this.customerFilterForm.controls["ToDate"].updateValueAndValidity();
			this.showError = true;
		}

		if (this.customerFilterForm.controls["ToDate"].value) {
			this.showError = false;
		}
	}

	restrictFrom(event: MatDatepickerInputEvent<Date>) {
		//this.Fromdate = event.value;
		if (this.customerFilterForm.controls["FromDate"].touched) {
			this.customerFilterForm.controls["ToDate"].setValidators(Validators.required);
			this.customerFilterForm.controls["ToDate"].updateValueAndValidity();
			this.showError = true;
		}

		if (this.customerFilterForm.controls["ToDate"].value) {
			this.showError = false;
		}
	}

	searchCustomers(searchText) {
		if (searchText != "") {
			this.customerList = this.dummyCustomerArray.filter((item) => {
				if (item.LeadName.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
					return true;
				}
				return false;
			});
		} else {
			this.teamFilter = this.teamList;
		}
	}

	// searchManager(searchText) {
	// 	if (searchText != '') {
	// 		this.teamFilter = this.teamList.filter(item => {
	// 			if (item.UserName.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
	// 				return true;
	// 			}
	// 			return false;
	// 		}
	// 		);
	// 	}
	// 	else {
	// 		this.teamFilter = this.teamList;
	// 	}

	// }

	closeDropdown() {
		this.clearData = true;
		this.customerFilterForm.controls["ToDate"].clearValidators();
		this.customerFilterForm.controls["ToDate"].updateValueAndValidity();
		this.customerFilterForm.reset();

		this.showError = false;
		this.viewLoading = false;
		this.dropdown.close();
		this.filterData = {
			CountryId: "",
			StateId: "",
			CityId: "",
			LocalityId: "",
			StatusIds: "",
			leaveStatusIds: "",
			UserStatusIds: "",
			SourceIds: "",
			StageIds: "",
			TypeIds: "",
			TerritoryIds: "",
			TagIds: "",
			UserIds: "",
			Category: "",
			FromDate: "",
			ToDate: "",
			dateType: "",
			MaxValue: "",
			MinValue: "",
			Sort: "",
			LeaveId: "",
			PunchDetails: "",
			PunchDetailsCheck: "",
			ReferBy: "",
			ReferralCode: "",
			CompanyName: "",
			Department: "",
			Branches: "",
			Designation: "",
		};
		this.stateList = [];
		this.cityList = [];
		this.localityList = [];
		setTimeout(() => {
			this.applyFilter();
		});
	}

	filterValue(searchCountry) {
		if (searchCountry != "") {
			this.filterArray = this.countryList.filter((option) => {
				return option.Name.toLowerCase().startsWith(searchCountry.toLowerCase());
			});
		} else {
			this.filterArray = this.countryList;
		}
	}
	filterValueState(searchState) {
		if (searchState != "") {
			this.filterArrayState = this.stateList.filter((option) => {
				return option.Name.toLowerCase().startsWith(searchState.toLowerCase());
			});
		} else {
			this.filterArrayState = this.stateList;
		}
	}
	filterValueCity(searchCity) {
		if (searchCity != "") {
			this.filterArrayCity = this.cityList.filter((option) => {
				return option.Name.toLowerCase().startsWith(searchCity.toLowerCase());
			});
		} else {
			this.filterArrayCity = this.cityList;
		}
	}
	searchManagerOfReferBy(filterReferBy) {
		if (filterReferBy != "") {
			this.referralReferByListArray = this.referralListArray.filter((option) => {
				return option.userName.toLowerCase().startsWith(filterReferBy.toLowerCase());
			});
		} else {
			this.referralReferByListArray = this.referralListArray;
		}
	}

	searchManagerOfCustomerReferBy(filterReferBy) {
		if (filterReferBy != "") {
			this.referralReferByListArray = this.referralListArray.filter((option) => {
				return option.ContactPerson.toLowerCase().startsWith(filterReferBy.toLowerCase());
			});
		} else {
			this.referralReferByListArray = this.referralListArray;
		}
	}
	getReferralData() {
		var referralData = {
			from: 1,
			to: -1,
			search: "",
			statusIds: "",
			fromDate: "",
			toDate: "",
		};
		this.myreferralService
			.getMyReferral(referralData)
			.pipe(
				map((myreferral) => {
					this.referralListArray = myreferral.data.referralUsersList;
					this.referralReferByListArray = myreferral.data.referralUsersList;
					for (var i = 0; i < this.referralReferByListArray.length; i++) {
						if (this.referralReferByListArray[i].CompanyName) {
							this.referralListOfBusiness.push(this.referralReferByListArray[i]);
							this.temp.push(this.referralReferByListArray[i]);
						}
					}
				})
			)
			.subscribe();
	}
	searchManagerOfCompanies(filterCompanies) {
		if (filterCompanies != "") {
			this.referralListOfBusiness = this.temp.filter((option) => {
				return option.CompanyName.toLowerCase().startsWith(filterCompanies.toLowerCase());
			});
		} else {
			this.referralListOfBusiness = this.temp;
		}
	}

	filterleaves(searchleavetypes) {
		if (searchleavetypes != "") {
			this.filterLeave = this.leaveTypes.filter((option) => {
				return option.Type.toLowerCase().startsWith(searchleavetypes.toLowerCase());
			});
		} else {
			this.assignDefaultValue();
		}
	}
	assignDefaultValue() {
		this.filterLeave = this.leaveTypes;
		this.filterLeaveValue.nativeElement.value = "";
	}
	statusEvent(event, checkbox) {
		this.filterData.PunchDetails = event.value;
		this.filterData.PunchDetailsCheck = checkbox;
		this.filterApplyEvent.emit({ filterData: this.filterData, filterList: this.filteredDataList });
	}

	getStatusLabel(value: string) {
		let obj = this.statusFilter.find((status) => status.value == value);
		return obj.name || "NA";
	}

	getMyTeam() {
		this.teamService
			.getMyTeam(this.currentUser.userId,this.invalidUser)
			.pipe(
				map((res)=>{
					if(res.Status == 200) {
						this.teamList = res.data;
						this.teamFilter = this.teamList;
						if(this.teamFilter.length>0){
							this.filteredMemberMulti.next(this.teamFilter.slice());
							this.setInitialValue();
						}

					}
				})
			)
			.subscribe();

	}

	setInvalidUser(isChecked:boolean){
		if(isChecked !== undefined){
		  console.log(isChecked);
		  this.invalidUser =  isChecked
		  
		  this.teamService.getMyTeam(this.currentUser.userId,this.invalidUser)
		  .pipe(
			map((res)=>{
				if(res.Status == 200) {
					this.teamList = res.data;
					this.teamFilter = this.teamList;
					if(this.teamFilter.length>0){
						this.filteredMemberMulti.next(this.teamFilter.slice());
						this.setInitialValue();
					}

				}
			})
		  )
		  .subscribe();
		}
	  }

	  dateRangeValidator(group: FormGroup): { [key: string]: any } | null {
		const fromDate = group.get('FromDate').value;
		const toDate = group.get('ToDate').value;
		if ((fromDate && !toDate) || (!fromDate && toDate)) {
		  return { 'bothDatesRequired': true };
		}
		return fromDate && toDate && fromDate > toDate ? { 'dateRangeInvalid': true } : null;
	  }
}
