import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[numbersOnly]',
    exportAs: 'numbersOnly'
})
export class NumberDirective {

  
    private regex: RegExp = new RegExp(/^\d*$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    constructor(private _el: ElementRef) { }

    @HostListener('keyup', ['$event']) onInputChange(event:KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
          }
          let current: string = this._el.nativeElement.value;
          const position = this._el.nativeElement.selectionStart;
          const next: string = [
            current.slice(0, position),
            event.key,
            current.slice(position)
          ].join('');
          if (next && !String(next).match(this.regex)) {
            event.preventDefault();
          }
          
        }

  @HostListener('input', ['$event']) onInput() {
    const inputValue: string = this._el.nativeElement.value;
    this._el.nativeElement.value = inputValue.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
  }
}