import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { OpportunitiesModel } from '../../../../../core/crm/opportunities/_models/opportunities.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
// Service
import { OpportunitiesService } from '../../../../../core/crm/opportunities/_services/opportunities.service'
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { AddproductdialogComponent } from '../add-product-dialog/add-product-dialog.component';
import { AddReasonDialogComponent } from '../add-reason-dialog/add-reason-dialog.component'
import { DealsPipelineService } from '../../../../../core/crm/deals-pipeline-setting/service/deals-pipeline-setting.service';
import { ToastrService } from 'ngx-toastr';
import { TruncatePipe } from '../../../../../core/_base/layout/pipes/truncate-text.pipe';

@Component({
  selector: 'kt-add-opportunities',
  templateUrl: './add-opportunities.dialog.component.html',
  styleUrls: ['./add-opportunities.dialog.component.scss'],
  providers: [DatePipe]
})
export class AddOpportunitiesDialogComponent implements OnInit {

  opportunity: OpportunitiesModel
  opportunityForm: FormGroup
  params = {
    From: 1,
    To: -1,
    Text: '',
  }
  leadData;
  productdata: any = []
  productIds: any = [];
  leadFilter: any;
  teamData;
  TagList;
  product: any[]
  productLength: number = 0;
  assignedToCount: number = 0;
  action: string;
  current = new Date();
  globalTags = [];
  isOpportunity: boolean = true;
  currentUser: any;
  private componentSubscriptions: Subscription;
  teamFilter: any;
  minDate = new Date()
  selectable = true;
  removable = false;
  addOnBlur = true;
  reasonList: any = [];
  lostReason: any = [];
  value: any;
  cusId: any;
  selectedReason: any = [];
  reasonListLength: any;
  opprtId: any;

  isLostReasonRequired: any;
  isProposalFileRequired: any;
  isWonFileRequired: any;
  PraposalpdfName: any = '';
  WonpdfName: any = ''
  imageName: string = '';
  imagePath: string = '';
  filesizeError: boolean = false
  loading: boolean = false;
  oppurtunityNumber: string;
  colorOptions = [
    { value: 'green', selected: false, },
    { value: 'red', selected: false },
    {value: 'yellow', selected: false }
  ];
  constructor(public dialogRef: MatDialogRef<AddOpportunitiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private opportunitiesService: OpportunitiesService,
    private ref: ChangeDetectorRef,
    private datepipe: DatePipe,
    private authService: AuthService,
    private dealsPipelineService: DealsPipelineService,
    private truncatepipe: TruncatePipe,
    private toast : ToastrService
  ) { }

  ngOnInit() {
    this.teamData = this.data.team
    this.teamFilter = this.data.team
    this.TagList = this.data.tag
    this.leadData = this.data.leads
    this.leadFilter = this.leadData
    this.authService.getCurrentUser().subscribe(x => { this.currentUser = x; });
    this.action = this.data.action;
	this.oppurtunityNumber = this.data.opportunityNo;


    this.dealsPipelineService.getMyData().subscribe(res => {

      this.isLostReasonRequired = res.data.IsReasonRequired,
        this.isProposalFileRequired = res.data.IsProposalAttachmentRequired
      this.isWonFileRequired = res.data.IsWonAttachmentRequired
      this.createForm();
    })
    this.createForm();
    if (this.action == 'View' || this.action == 'Edit') {

      this.getVisitData(this.data.id);
    } else {
      this.opportunity = this.data.id;
    }

  }
  handleCheckboxChange(clickedOption: any) {
    this.colorOptions.forEach(option => {
      if (option !== clickedOption) {
        option.selected = false;
      } else {
        option.selected = !option.selected;
        if (option.selected) {
          this.opportunityForm.controls['Color'].patchValue(option.value);
        } else {
          this.opportunityForm.controls['Color'].patchValue('');
        }
      }
    });
  }
  setColorCode() {
    let value = this.opportunityForm.controls['Color'].value;
    if (value) {
      
      this.colorOptions.forEach(option => {
        if (option.value === value) {
          option.selected = true;  
        } else {
          option.selected = false;
        }
        })
    }
  }
  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  createForm() {
    this.opportunityForm = this.fb.group({
      leadId: ['', Validators.required],
      opportunitiesName: ['', Validators.required],
      stage: ['', Validators.required],
      ProductIds: [''],
      ProductDisplayIds: [''],
      assignedTo: ['', Validators.required],
      value: ['', Validators.required],
      closeDate: ['', Validators.required],
      notes: [],
      TagIds: [''],
      reason: [''],
      ProposalPDFInput: [''],
      WonPDFInput: [''],
      Color:['']
    });
  }

  getLeads() {
    this.opportunitiesService.getLeads(this.params)
      .pipe(map(res => {
        if (res.Status == 200)
          this.leadData = res.data.Leads
        this.leadFilter = this.leadData
      }))
      .subscribe()
  }

  openproduct(controlName) {
    const controls = this.opportunityForm.controls;
    let controlname;
    let params;
    if (controlName == 'ProductIds') {
      controls.ProductDisplayIds.updateValueAndValidity();
      controlname = controls.ProductDisplayIds;
      params = { productsIds: controls.ProductIds.value, controlname: 'ProductIds', Action: this.action };

    }
    const dialogRef = this.dialog.open(AddproductdialogComponent, { data: params, width: '400px' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return
      if (controlName == 'ProductIds') {
        this.productIds = [];
        this.assignedToCount = 0
        res.products.forEach(result => {
          if (!this.productIds.includes(result.Id)) {
            this.productIds.push(result);
            this.assignedToCount += 1;
            const controls = this.opportunityForm.controls;
            controls.ProductIds.setValue(this.productIds);
          }
        })
      }

      this.productLength = this.productIds.length;
      if (this.productIds.length < 1 && controlname == controls.ProductDisplayIds) {
        controlname.setValue(this.productIds.length + ' Products');
        controls.ProductIds.patchValue('');
      }
      if (this.productIds.length > 1 && controlname == controls.ProductDisplayIds) {
        controlname.setValue(this.productIds.length + ' Products');
      }
      if (this.productIds.length == 1 && controlname == controls.ProductDisplayIds) {
        controlname.setValue(this.productIds.length + ' Product');
      }
    });
  }

  openReason() {
    if (this.reasonList != undefined) {
      var checkedList = this.reasonList
      var params1 = {
        checkedList
      }
    }
    else {
      params1 = null
    }
    this.value = this.cusId
    let params = this.reasonList;
    const dialogRef = this.dialog.open(AddReasonDialogComponent, { data: params1, width: '400px' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return
      if (res) {
        this.reasonList = res.reasons;
        for (let i = 0; i < this.reasonList.length; i++) {
          if (this.reasonList[i].ReasonId == undefined) {
            let notFormatedData = this.reasonList[i]
            var temp = {  //formatting the data as required for the POST method while posting checked lost Reason List
              ReasonId: notFormatedData
            }
            this.reasonList.splice(i, 1, temp)
          }
        }
        this.opportunitiesService.createSelectedReasonsList(this.reasonList)
        this.reasonListLength = this.reasonList.length;
        const reasonListLength = this.reasonList.length;
        if (reasonListLength > 0) {
          this.opportunityForm.controls.reason.patchValue(reasonListLength + " " + "Reasons");
        }
        else {
          this.opportunityForm.controls.reason.patchValue(null);
        }
      }
    });
  }

  getSelectedLostReason() {
    this.opportunitiesService.getSelectedReason(this.cusId).pipe(
      map(res => {
        if (res.Status == 200) {
          this.lostReason = res.data.OpportunityReason;
        }
      })
    ).subscribe();
  }

  remove(reason) {
    const index = this.reasonList.indexOf(reason);
    if (index >= 0) {
      this.reasonList.splice(index, 1);
    }
  }

  getTagList() {
    this.opportunitiesService.getTag().subscribe(res => this.TagList = res.data);
    this.ref.detectChanges();
  }

  getVisitData(Id) {
    this.cusId = Id
    this.getSelectedLostReason();
    this.opportunitiesService.getVisitData(Id)
      .pipe(map(res => {
        this.opportunity = res.data;
        if (this.action == 'Edit') {
          const controls = this.opportunityForm.controls;
          controls.leadId.setValue(this.opportunity.LeadId);
          controls.opportunitiesName.setValue(this.opportunity.OpportunityName);
          controls.stage.setValue((this.opportunity.OpportunityStatusId).toString());
          controls.assignedTo.setValue(this.opportunity.AssignedToUserId);
          controls.value.setValue(this.opportunity.Value);
          controls.closeDate.setValue(new Date(this.opportunity.CloseDate));
          controls.notes.setValue(this.opportunity.Notes);
          controls.TagIds.setValue(this.opportunity.Tags);
          controls.ProductIds.setValue(this.opportunity.OpportunityProduct);
          controls.Color.setValue(this.opportunity.Color);
          controls.ProductDisplayIds.setValue((controls.ProductIds.value.length == 1) ? controls.ProductIds.value.length + ' Product' : controls.ProductIds.value.length + ' Products');
          this.globalTags = this.opportunity.Tags;

          let proposalfilename = (/[^/]*$/.exec(this.opportunity.Quotation)[0]);
          this.PraposalpdfName = proposalfilename.substring(proposalfilename.indexOf('_') + 1);
          this.PraposalpdfName = this.truncatepipe.transform(this.PraposalpdfName, ['20'])
          this.imagePath = this.opportunity.Quotation;
          controls.ProposalPDFInput.setValue(this.PraposalpdfName)

        }
        this.setColorCode();
        if (this.action === 'View') {
          let filename = (/[^/]*$/.exec(this.opportunity.Quotation)[0]);
          this.PraposalpdfName = filename.substring(filename.indexOf('_') + 1)
          let wonfilename = (/[^/]*$/.exec(this.opportunity.WonOpportunity)[0]);
          this.WonpdfName = wonfilename.substring(wonfilename.indexOf('_') + 1)
        }
      }))
      .subscribe()
  }

  getmatseletvalue(value) {
    let controls = this.opportunityForm.controls
    if (value == 3 && this.isProposalFileRequired) {
      controls['ProposalPDFInput'].setValidators(Validators.required);
      controls['ProposalPDFInput'].updateValueAndValidity()
    }
    else if (value == 4 && this.isWonFileRequired) {
      controls['WonPDFInput'].setValidators(Validators.required);
      controls['WonPDFInput'].updateValueAndValidity()
    }
    else if (value == 5 && this.isLostReasonRequired) {
      controls['reason'].setValidators(Validators.required);
      controls['reason'].updateValueAndValidity()
    }
    else if (value != 3) {
      controls['ProposalPDFInput'].clearValidators()
      controls['ProposalPDFInput'].updateValueAndValidity()
    }
    else if (value != 4) {
      controls['WonPDFInput'].clearValidators()
      controls['WonPDFInput'].updateValueAndValidity()
    }
  }

  handleFileSelect(inputValue: any, controlName): void {
    if (inputValue.files[0] && inputValue.files[0].size <= 2000000) {
      this.filesizeError = false
      if (controlName == 'proposalpdf') {
        if(inputValue.files[0].type=='application/pdf'){
          var file = inputValue.files[0];
          this.PraposalpdfName = this.truncatepipe.transform(file.name, ['20']);
          this.imageName = file.name.replace(/\\/g, "/");
          this.imageName = this.imageName.substring(0, this.imageName.lastIndexOf('.'));
          var reader = new FileReader();
          reader.onload = (e: any) => {
            var binaryData = e.target.result;
            var base64String = btoa(binaryData);
            this.imagePath = base64String;
            this.opportunityForm.patchValue({
              ProposalPDFInput: this.PraposalpdfName
            });
          }
          reader.readAsBinaryString(file);
        }else{
          this.toast.error('Please Select PDF file')
        }

      }
      else if (controlName == 'wonpdf') {
        var file = inputValue.files[0];
        this.WonpdfName = this.truncatepipe.transform(file.name, ['20']);
        this.imageName = file.name.replace(/\\/g, "/");
        this.imageName = this.imageName.substring(0, this.imageName.lastIndexOf('.'));
        var reader = new FileReader();
        reader.onload = (e: any) => {
          var binaryData = e.target.result;
          var base64String = btoa(binaryData);
          this.imagePath = base64String;
          this.opportunityForm.patchValue({
            WonPDFInput: this.WonpdfName
          });
        }
        reader.readAsBinaryString(file);
      }
      else if (inputValue.files[0] && inputValue.files[0].size > 2000000) {
        this.filesizeError = true
      }
    }
  }

  removeFile(controlName) {
    if (controlName == 'proposalpdf') {
      this.opportunityForm.patchValue({
        ProposalPDFInput: null,
      });
      this.PraposalpdfName = ''
    }
    if (controlName == 'wonpdf') {
      this.opportunityForm.patchValue({
        WonPDFInput: null,
      });
      this.WonpdfName = ''
    }
  }

  prepareCustomer(): OpportunitiesModel {
    const controls = this.opportunityForm.controls;
    const _opportunities = new OpportunitiesModel();
    _opportunities.LeadId = controls['leadId'].value;
    _opportunities.AssignedToUserId = controls['assignedTo'].value;
    _opportunities.CloseDate = this.datepipe.transform(controls['closeDate'].value, 'MM/dd/yyyy');
    _opportunities.Notes = controls['notes'].value;
    _opportunities.OpportunityName = controls['opportunitiesName'].value;
    _opportunities.OpportunityStatusId = controls['stage'].value;
    _opportunities.OpportunityProduct = controls['ProductIds'].value;
    _opportunities.Value = controls['value'].value;
    _opportunities.Color = controls['Color'].value;
    let tags = controls['TagIds'].value;
    if (tags.Tags) {
      for (var i = tags.Tags.length - 1; i > -1; i--) {
        if (tags.Tags[i].IsActive == false) {

        }
        else {
          Object.assign(tags.Tags[i], { IsActive: true });
        }
        if (!tags.Tags[i].Id && this.action == "Edit") {
          Object.assign(tags.Tags[i], { Id: -1 });
        }
        if (tags.Tags[i].Id) {
          Object.assign(tags.Tags[i], { TagId: tags.Tags[i].Id });
        } else {
          Object.assign(tags.Tags[i], { TagId: -1 });
        }
      }
      _opportunities.OpportunityTags = tags.Tags;
    }
    else {
      let intersection = this.globalTags.filter(x => {
        !tags.includes(x.TagName);
      });
      for (var i = tags.length - 1; i > -1; i--) {
        if (tags[i].IsActive == false) {
        }
        else {
          Object.assign(tags[i], { IsActive: true });
        }

        if (tags[i].Id) {
          Object.assign(tags[i], { TagId: tags[i].Id });
        } else {
          Object.assign(tags[i], { TagId: -1 });
        }
      }
      _opportunities.OpportunityTags = tags;
    }
    if (controls.stage.value == 3 && controls.ProposalPDFInput.value != null) {
      _opportunities.MultimediaData = this.imagePath;
      _opportunities.MultimediaFileName = this.imageName
      _opportunities.IsFileAttached = true
    }
    if (controls.stage.value == 4 && controls.WonPDFInput.value != null) {
      _opportunities.MultimediaData = this.imagePath;
      _opportunities.MultimediaFileName = this.imageName
      _opportunities.IsFileAttached = true
    }
    if (controls.ProposalPDFInput.value == null) {
      _opportunities.IsFileAttached = false
    }
    if (controls.WonPDFInput.value == null) {
      _opportunities.IsFileAttached = false
    }
    return _opportunities;
  }

  onSubmit() {
    const controls = this.opportunityForm.controls;
    this.loading = false
    if (controls.stage.value == 3 || controls.stage.value == 4){
      if(this.isProposalFileRequired) {
        controls['ProposalPDFInput'].setValidators(Validators.required);
        controls['ProposalPDFInput'].updateValueAndValidity()
      }
     else if(this.isWonFileRequired) {
        controls['WonPDFInput'].setValidators(Validators.required);
        controls['WonPDFInput'].updateValueAndValidity()
      }
    }
    if (this.opportunityForm.invalid) {
      this.opportunityForm.markAllAsTouched();
      return;
    }
    const opportunities = this.prepareCustomer();
    if (this.action == 'Add') {
    this.loading = false
      this.createOpportunities(opportunities)
    this.loading = true
    } else {
      this.updateOpportunities(this.data.opportunities.Id, opportunities)
      this.loading = true
    }
  }

  createOpportunities(opportunities) {
    opportunities.OpportunityReasons = this.reasonList;
    this.opportunitiesService.create(opportunities)
      .pipe(map(res => {
        if (res.Status == 200) {
          this.loading = true
          this.dialogRef.close({ opportunities, isEdit: false });
          this.loading = false
        }
      }))
      .subscribe();
  }

  updateOpportunities(Id, opportunities) {
	console.log(Id)
    opportunities.OpportunityReasons = this.reasonList;
    if (this.imagePath === this.opportunity.Quotation) {
      opportunities.MultimediaFileName = ''
      opportunities.MultimediaData = ''
    }
    this.opportunitiesService.update(Id, opportunities)
      .pipe(map(res => {
        if (res.Status == 200) {
          this.loading = false
          this.dialogRef.close({ opportunities, isEdit: false });
        }
      }))
      .subscribe();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  searchManager(searchText, type) {
    if (type == 'lead') {
      if (searchText != '') {
        this.leadFilter = this.leadData.filter(item => {
          if (item.LeadName.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
            return true;
          }
          return false;
        }
        );
      }
      else {
        this.leadFilter = this.leadData;
      }
    } else {
      if (searchText != '') {
        this.teamFilter = this.teamData.filter(item => {
          if (item.UserName.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
            return true;
          }
          return false;
        }
        );
      }
      else {
        this.teamFilter = this.teamData;
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
